import React, { useState } from 'react'
import { useForms } from '../hooks/useForms'
import { SignUpButton } from './Icons'
import { PortableText } from './PortableText'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const CareerForm = ({ role, vettingQuestions }) => {
  const { career } = useForms()
  const [value, setValue] = useState({})
  const [success, setSuccess] = useState(false)
  const MAX_TOTAL_SIZE = 8 * 1024 * 1024
  const [totalFileSize, setTotalFileSize] = useState(0)
  const [error, setError] = useState('')
  const [fileSizes, setFileSizes] = useState({
    resume: null,
    coverLetter: null,
  })

  const handleChange = e => {
    const { name, value, files } = e.target
    if (files && files.length > 0) {
      const file = files[0]
      const fileSize = file.size

      const newTotalSize = totalFileSize - (value[name]?.size || 0) + fileSize

      if (newTotalSize > MAX_TOTAL_SIZE) {
        setError('The combined file size must not exceed 8MB.')
        return
      }

      setError('')
      setValue(prevValue => ({ ...prevValue, [name]: file }))
      setTotalFileSize(newTotalSize)
      setFileSizes(prevSizes => ({
        ...prevSizes,
        [name]: `${(fileSize / 1024).toFixed(2)} KB`,
      }))
    } else {
      setValue(prevValue => ({ ...prevValue, [name]: value }))
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target

    if (error) {
      alert('Please resolve the errors before submitting the form.')
      return
    }
    fetch('/', {
      method: 'POST',
      body: new FormData(form),
    })
      .then(() => {
        form.reset()
        setSuccess(true)
      })
      .catch(error => alert(error))
  }

  return (
    <div className="w-full mx-auto my-auto md:pb-12 border-t-2">
      {!success ? (
        <>
          <form
            name="career"
            method="POST"
            action="/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            className="body flex flex-col"
            encType="multipart/form-data"
          >
            <h2 className="w-full h4 pt-12 whitespace-nowrap">
              Apply for position
            </h2>
            <input type="hidden" name="form-name" value="career" />
            <input type="hidden" name="role" value={role} />
            <div className="flex flex-col flex-1 relative justify-evenly pt-[5%]">
              <div className="flex flex-col">
                <div className="flex gap-1 max-w-[680px]">
                  <input
                    type="text"
                    name="firstName"
                    autoComplete="off"
                    onChange={handleChange}
                    className="body-sm px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                    placeholder="First Name"
                    required
                  />
                  <span className="text-red h-fit body leading-none w-[8px]">
                    *
                  </span>
                </div>
                <div className="flex gap-1 max-w-[680px]">
                  <input
                    type="text"
                    name="lastName"
                    autoComplete="off"
                    onChange={handleChange}
                    className="body-sm px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                    placeholder="Last Name"
                    required
                  />
                  <span className="text-red h-fit body leading-none w-[8px]">
                    *
                  </span>
                </div>
                <div className="flex gap-1 max-w-[680px]">
                  <input
                    type="tel"
                    name="phoneNumber"
                    autoComplete="off"
                    onChange={handleChange}
                    className="body-sm px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                    placeholder="Phone Number"
                    required
                  />
                  <span className="text-red h-fit body leading-none w-[8px]">
                    *
                  </span>
                </div>
                <div className="flex gap-1 max-w-[680px]">
                  <input
                    type="email"
                    name="email"
                    autoComplete="off"
                    onChange={handleChange}
                    className="body-sm px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                    placeholder="Email"
                    required
                  />
                  <span className="text-red h-fit body leading-none w-[8px]">
                    *
                  </span>
                </div>

                <div className="flex flex-col max-w-[650px] mt-5 mb-7">
                  {error && <p className="text-red text-sm mt-2">{error}</p>}
                  <label className="font-semibold mb-2">
                    Resume
                    <span className="text-red h-fit body leading-none ml-[0.2%] mr-1 font-normal">
                      *
                    </span>
                    <span className="font-normal text-sm">pdf preferred</span>
                  </label>
                  <input
                    type="file"
                    name="resume"
                    accept=".pdf,.doc,.docx"
                    onChange={handleChange}
                    className="body-sm"
                    required
                  />
                  {fileSizes.resume && (
                    <p className="text-sm text-gray-500 mt-2">
                      File size: {fileSizes.resume}
                    </p>
                  )}
                </div>
                <div className="flex flex-col max-w-[650px] mb-10">
                  <label className="font-semibold mb-2">
                    Cover Letter
                    <span className="font-normal text-sm"> pdf preferred</span>
                  </label>
                  <input
                    type="file"
                    name="coverLetter"
                    accept=".pdf,.doc,.docx"
                    onChange={handleChange}
                    className="body-sm"
                  />
                  {fileSizes.coverLetter && (
                    <p className="text-sm text-gray-500 mt-2">
                      File size: {fileSizes.coverLetter}
                    </p>
                  )}
                </div>

                <input
                  type="text"
                  name="Website"
                  autoComplete="off"
                  onChange={handleChange}
                  className="body-sm px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                  placeholder="Website"
                />
                <input
                  type="text"
                  name="linkedIn"
                  autoComplete="off"
                  onChange={handleChange}
                  className="body-sm px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                  placeholder="LinkedIn Profile"
                />
                <select
                  name="category"
                  onBlur={handleChange}
                  className="body-sm px-1 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                >
                  <option value="" defaultValue>
                    How did you hear about this opportunity?
                  </option>
                  {career?.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
                {value.category === 'Other (please specify)' && (
                  <div className="flex gap-1 max-w-[680px]">
                    <input
                      type="text"
                      name="otherCategory"
                      placeholder="Please specify"
                      onChange={handleChange}
                      className="body-sm px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                      required
                    />
                    <span className="text-red h-fit body leading-none w-[8px]">
                      *
                    </span>
                  </div>
                )}
                <input
                  type="hidden"
                  name="other"
                  value={value.otherCategory || ''}
                />
              </div>
              {vettingQuestions && (
                <div className="mt-4 max-w-[650px]">
                  <label className="block mb-2 font-semibold">
                    Vetting Questions
                    <span className="relative w-[8px]">
                      <span className="text-red h-fit body leading-none right-[-2%] font-normal w-full absolute">
                        *
                      </span>
                    </span>
                  </label>
                  <PortableText
                    className="xl:max-w-[80%] body-sm"
                    blocks={vettingQuestions}
                  />
                  <textarea
                    name="questions"
                    onChange={handleChange}
                    className="p-2 bg-transparent border-2 focus-field w-full mt-4"
                    rows={5}
                    placeholder="Answer questions here"
                    required
                  />
                </div>
              )}
              <input
                type="hidden"
                name="vettingQuestions"
                value={value.questions || ''}
              />
              <div className="mt-4 max-w-[650px]">
                <label className="block mb-2 font-semibold">
                  Are you legally authorized to work in the United States?
                  <span className="relative w-[8px]">
                    <span className="text-red h-fit body leading-none right-[-1%] font-normal w-full absolute">
                      *
                    </span>
                  </span>
                </label>
                <div className="flex space-x-4">
                  <label className="body-sm flex items-center">
                    <input
                      type="radio"
                      name="workAuthorization"
                      value="yes"
                      onChange={handleChange}
                      className="mr-2"
                      required
                    />
                    Yes
                  </label>
                  <label className="body-sm flex items-center">
                    <input
                      type="radio"
                      name="workAuthorization"
                      value="no"
                      onChange={handleChange}
                      className="mr-2"
                      required
                    />
                    No
                  </label>
                </div>
              </div>
              <div className="mt-4 max-w-[650px]">
                <label className="block mb-2 font-semibold">
                  Do you now or in the future require visa sponsorship to
                  continue working in the United States?
                  <span className="relative w-[8px]">
                    <span className="text-red h-fit body leading-none right-[-2%] absolute font-normal w-full">
                      *
                    </span>
                  </span>
                </label>
                <div className="flex space-x-4">
                  <label className="body-sm flex items-center">
                    <input
                      type="radio"
                      name="visaSponsorship"
                      value="yes"
                      onChange={handleChange}
                      className="mr-2"
                      required
                    />
                    Yes
                  </label>
                  <label className="body-sm flex items-center">
                    <input
                      type="radio"
                      name="visaSponsorship"
                      value="no"
                      onChange={handleChange}
                      className="mr-2"
                      required
                    />
                    No
                  </label>
                </div>
              </div>
              <div className="mt-12 mb-24 max-w-[650px] w-full flex items-center">
                <button
                  className="relative flex flex-col items-center self-center mx-auto"
                  type="submit"
                >
                  <SignUpButton className="absolute w-[180%] top-0 bottom-0 h-auto" />
                  <div className="h4-alt p-2 md:p-3">Submit</div>
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <div className="h-full pt-24 pb-32 md:pt-32 md:pb-36">
          <div className="relative flex w-full h-full justify-center items-center max-w-[650px]">
            <p className="body">
              Thanks for submitting your application to our candidate pool!
              We're excited to learn more about you and will be in touch if
              there is an opening that fits your profile.
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
