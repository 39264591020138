import React from 'react'
import { graphql } from 'gatsby'
import Meta from '../components/Meta'
import CareerPosition from '../components/CareerPosition'
import { CareerForm } from '../components/CareerForm'

export const query = graphql`
  query CareerPositionTemplateQuery($slug: String) {
    careerPosition: sanityCareerPosition(slug: { current: { eq: $slug } }) {
      _id
      slug {
        current
      }
      role
      location
      jobDescription {
        aboutUs
        _rawAboutPosition
        _rawExpectations
        _rawAdditionalQualifications
        _rawQualifications
      }
      _rawNewCultureValues
      _rawDisclaimer
      payRange
      displayVettingQuestions
      _rawVettingQuestions
    }
  }
`

const CareerPositionTemplate = ({ data, pageContext }) => {
  const careerPosition = data && data.careerPosition
  const allCareerPositionData = {
    ...careerPosition,
    ...pageContext,
  }
  const vettingQuestions = allCareerPositionData.displayVettingQuestions ? allCareerPositionData._rawVettingQuestions : null;
  return (
    <>
      <Meta pageTitle={''} props={data?.careerPosition?.seo} />
      <div className="fluid-container">
        {careerPosition && <CareerPosition {...allCareerPositionData} />}
        <section className='px-4 md:px-[5%] xl:max-w-[75%]'>
          <CareerForm role={allCareerPositionData.role} vettingQuestions={vettingQuestions} />
        </section>
      </div>
    </>
  )
}

export default CareerPositionTemplate
