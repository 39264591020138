import React from 'react'
import { PortableText } from './PortableText'
import { Link } from 'gatsby'
import { ArrowLeftShort } from './Icons'

const CareerPosition = ({
  role,
  location,
  _rawNewCultureValues,
  _rawDisclaimer,
  payRange,
  jobDescription,
}) => {
  return (
    <>
      <section className="w-full pt-24 md:header-padding relative pb-12">
        <div className="px-4 pt-9 md:px-[5%] md:pt-[2%] xl:max-w-[85%]">
          <Link to="/careers/positions" className="w-fit block">
            <span className="flex align-center w-fit">
              <ArrowLeftShort className="w-10 h-4 mt-1 md:w-12 md:h-5" />
              Back to jobs
            </span>
          </Link>
          <h1 className="h4 mt-12 mb-[1%]">{role}</h1>
          <h2 className="body ">{location}</h2>

          <div className="my-[4%]">
            <h3 className="pb-[2%] h6">About Us</h3>
            <p className=" body-sm">{jobDescription.aboutUs}</p>
          </div>

          <div className="mb-[4%]">
            <h3 className="pb-[2%] h6">What will you be doing</h3>
            <PortableText
              className="body-sm"
              blocks={jobDescription._rawAboutPosition}
            />
          </div>

          <div className="mb-[4%]">
            <h3 className="pb-[2%] h6">What we expect from you</h3>
            <PortableText
              className="body-sm"
              blocks={jobDescription._rawExpectations}
            />
          </div>

          <div className="mb-[4%]">
            <h3 className="pb-[2%] h6">Even better if you had</h3>
            <PortableText
              className="body-sm"
              blocks={jobDescription._rawAdditionalQualifications}
            />
          </div>

          <div className="mb-[4%]">
            <h3 className="pb-[2%] h6">How to know you are a great fit</h3>
            <PortableText
              className="body-sm"
              blocks={jobDescription._rawQualifications}
            />
          </div>

          <div className="mb-[4%]">
            <h3 className="pb-[2%] h6">New Culture Values</h3>
            <PortableText className="body-sm" blocks={_rawNewCultureValues} />
          </div>

          <div className="mb-[4%]">
            <PortableText className="body-sm" blocks={_rawDisclaimer} />
          </div>

          <div>
            <h3 className="pb-[2%] h6">Compensation</h3>
            <p className="body-sm">{payRange}</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default CareerPosition
